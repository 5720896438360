import React, { useState } from 'react';
import { mediaQueries } from 'src/style/breakpoints';
import { PlayButton } from '../../components/Button';
import ModalVideo from '../Modal/ModalVideo';
import { VideoItem } from './index';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ArrowPurple } from '../Icons/arrows';
import BackgroundImage from 'gatsby-background-image';
import { useMediaQueries } from '../../utils/MediaQueries';

export const VideosBlock = ({ content, handleOnClick, indexSelected }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoStarted, setVideoStart] = useState(false);
  const mqs = useMediaQueries();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {mqs.tabletLandscapeUp ? (
        <>
          <BackgroundImage
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            fluid={content[indexSelected].thumbnail}
            alt={content[indexSelected].alt}
            Tag="div"
            css={{
              borderRadius: 5,
              [mediaQueries.phoneOnly]: {
                minHeight: 240,
              },
              [mediaQueries.tabletPortraitUp]: {
                minHeight: 380,
              },
              [mediaQueries.tabletLandscapeUp]: {
                maxHeight: 380,
              },

              [mediaQueries.desktopUp]: {
                maxHeight: 400,
              },
              WebkitMaskImage: '-webkit-radial-gradient(white, black)',
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundPosition: '50%',
              overflow: 'hidden',
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.07) 0px 4px 12px',
              transitionProperty: 'all',
              transitionDuration: '0.3s',
              transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
              transitionDelay: '0s',
              '&:hover': { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 8px 15px' },
            }}
          >
            <div
              css={{
                '&:before': {
                  backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                  position: 'absolute',
                  content: '""',
                  display: 'block',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.8,
                  transitionProperty: 'all',
                  transitionDuration: '0.3s',
                  transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
                  transitionDelay: '0s',
                },
                '&:hover:before': {
                  opacity: 1,
                },
              }}
            >
              <div
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  opacity: 0.8,
                }}
              >
                <PlayButton
                  onClick={() => {
                    setModalOpen(!modalOpen);
                  }}
                />
              </div>
            </div>
          </BackgroundImage>

          {modalOpen && (
            <ModalVideo
              isOpen={modalOpen}
              link={content[indexSelected].link}
              onClose={() => setModalOpen(false)}
            />
          )}
        </>
      ) : (
        <div
          css={{
            position: 'relative',
            boxShadow: 'rgba(0, 0, 0, 0.07) 0px 4px 12px',
          }}
        >
          <BackgroundImage
            onClick={() => {
              setVideoStart(true);
            }}
            fluid={content[indexSelected].thumbnail}
            alt={content[indexSelected].alt}
            Tag="div"
            css={{
              borderRadius: 5,
              [mediaQueries.phoneOnly]: {
                minHeight: 240,
              },
              [mediaQueries.tabletPortraitUp]: {
                minHeight: 380,
              },
              [mediaQueries.tabletLandscapeUp]: {
                maxHeight: 380,
              },

              [mediaQueries.desktopUp]: {
                maxHeight: 400,
              },
              WebkitMaskImage: '-webkit-radial-gradient(white, black)',
              objectFit: 'cover',
              backgroundSize: 'cover',
              backgroundPosition: '50%',
              overflow: 'hidden',
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                '&:before': {
                  backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                  position: 'absolute',
                  content: '""',
                  display: 'block',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.8,
                },
              }}
            >
              <div
                css={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <PlayButton />
              </div>
            </div>
          </BackgroundImage>

          {videoStarted && (
            <div
              css={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',

                cursor: 'pointer',
              }}
            >
              <iframe
                title="youtube-video"
                style={{
                  width: '100%',
                  height: '100%',
                }}
                src={content[indexSelected].link + '?autoplay=1&mute=1&enablejsapi=1'}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}
        </div>
      )}
      <CarouselProvider
        naturalSlideWidth={20}
        naturalSlideHeight={13}
        totalSlides={7}
        visibleSlides={3}
        dragEnabled={true}
        css={{ outline: 'none' }}
      >
        <Slider
          css={{
            paddingBottom: 10,
            marginRight: -3.1,
          }}
        >
          {content.map((item, index) => {
            return (
              <Slide
                index={item.index}
                key={item.title}
                onClick={() => {
                  handleOnClick(item.index);
                }}
                aria-label={item.title}
              >
                <VideoItem
                  title={item.title}
                  carouselThumbnails={item.thumbnail}
                  alt={item.alt}
                  indexSelected={indexSelected}
                  index={index}
                />
              </Slide>
            );
          })}
        </Slider>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <ButtonBack css={{ outline: 'none', paddingRight: 8 }}>
            <ArrowPurple css={{ transform: 'rotate(180deg)' }} />
          </ButtonBack>
          <Dot css={{ backgroundColor: 'red' }} />
          <ButtonNext css={{ outline: 'none', paddingLeft: 8 }}>
            <ArrowPurple />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
};
export default VideosBlock;
